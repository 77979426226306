import { HeroModuleNode } from '@/components/HeroBuilder/modules';

import { extractItems } from './GraphQLHelper';

export const hasHomepageHero = (content: HeroBuilderFragment) => {
  const nodes = extractItems<HeroModuleNode>(content.nodes);
  const heroHomepageTypenames = [
    'heroBuilder_homepageHeroWithCarousel_BlockType',
    'heroBuilder_homepageHeroWithImage_BlockType',
    'heroBuilder_homepageHeroWithVideo_BlockType',
  ];
  return nodes?.[0]?.__typename && heroHomepageTypenames.includes(nodes[0].__typename);
};

export const hasLandingHero = (content: HeroBuilderFragment) => {
  const nodes = extractItems<HeroModuleNode>(content.nodes);
  const heroLandingTypenames = ['heroBuilder_heroLanding_BlockType'];
  return nodes?.[0]?.__typename && heroLandingTypenames.includes(nodes[0].__typename);
};
