import { HeroSlots } from '@/components/HeroModule';
import { HeroSlotType } from '@/components/HeroModule/slots';
import { extractItems } from '@/lib/GraphQLHelper';

type ChildNode =
  | VizyHeadingFragment
  | VizyParagraphFragment
  | SubHeroHomepageCtaDynamicButtonFragment
  | SubHeroHomepageCtaDynamicFormFragment
  | SubHeroHomepageCtaFragment
  | SubHeroHomepageStaticButtonFragment;

export function convertToSlot(node: ChildNode): Nullable<HeroSlotType> {
  switch (node.__typename) {
    case 'VizyNode_Heading':
      if (!node.html) return null;
      return HeroSlots.Heading(node.html);
    case 'VizyNode_Paragraph':
      if (!node.html) return null;
      return HeroSlots.Paragraph(node.html);
    case 'subHeroHomepageCta_dynamicButton_BlockType':
      if (!node.heroModuleDynamicButtonVariants) return null;
      return HeroSlots.DynamicButton(node);
    case 'subHeroHomepageCta_dynamicForm_BlockType':
      if (!node.heroModuleDynamicFormVariants) return null;
      return HeroSlots.DynamicForm(node);
    case 'subHeroHomepageCta_staticButton_BlockType':
      if (!node.customLinkField) return null;
      return HeroSlots.StaticButton(node);
    default:
      return null;
  }
}

export default function convertToSlots(node?: Nullable<SubHeroHomepageCtaFragment>) {
  const nodes =
    extractItems<ChildNode>(node?.nodes)
      ?.map(convertToSlot)
      .filter((s): s is HeroSlotType => !!s) || [];

  return nodes;
}
