import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';
import slotDynamicButton, { slotDynamicButtonVariants } from '@/components/HeroModule/slots/DynamicButton';
import { SSRContext } from '@/typings/SSRContext';

import convertToSlots from '../submodules/SubHeroHomepageCTA';
import convertToImageSlots from '../submodules/SubHeroImages';

interface Props {
  node: HeroHomepageWithCarouselFragment;
}

const HeroHomepageWithCarousel = ({ node }: Props) => {
  const headlines = node.heroHeadlines?.trim().split('\n') || [];
  const slots = useMemo(() => convertToSlots(node.subHeroHomepageCta), [node.subHeroHomepageCta]);
  const imageSlots = useMemo(() => convertToImageSlots(node.subHeroImages), [node.subHeroImages]);

  return <HeroModule variant="homepageHeroWithCarousel" slots={slots} imageSlots={imageSlots} headlines={headlines} />;
};

HeroHomepageWithCarousel.prefetch = async (context: SSRContext, node: HeroHomepageWithCarouselFragment) => {
  // prefetch dynamicButton
  const nodes = node.subHeroHomepageCta?.nodes || [];
  for (const node of nodes) {
    if (
      node &&
      '__typename' in node &&
      node?.__typename === 'subHeroHomepageCta_dynamicButton_BlockType' &&
      node.heroModuleDynamicButtonVariants
    ) {
      await slotDynamicButton.prefetch(context, node.heroModuleDynamicButtonVariants as slotDynamicButtonVariants);
    }
  }

  return;
};

export default HeroHomepageWithCarousel;
