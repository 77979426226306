import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';

import convertToImageSlots from '../submodules/SubHeroImages';
import convertToSlots from '../submodules/SubHeroLandingContent';

interface Props {
  node: HeroLandingFragment;
}

const HeroLanding = ({ node }: Props) => {
  const slots = useMemo(() => convertToSlots(node.subHeroLandingContent), [node.subHeroLandingContent]);
  const imageSlots = useMemo(() => convertToImageSlots(node.subHeroImages), [node.subHeroImages]);

  return (
    <HeroModule
      variant="heroLanding"
      slots={slots}
      imageSlots={imageSlots}
      overrideBackground={node.backgroundColour}
    />
  );
};

export default HeroLanding;
