import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';

import convertToSlots from '../submodules/SubHeroContent';
import { convertToFormBlock } from '../submodules/SubHeroForm';
import convertToImageSlots from '../submodules/SubHeroImages';

interface Props {
  node: HeroHomepageWithFormFragment;
}

export default function HeroHomepageWithForm({ node }: Props) {
  const slots = useMemo(() => convertToSlots(node.subHeroContent), [node.subHeroContent]);
  const form = useMemo(() => convertToFormBlock(node.subHeroForm), [node.subHeroForm]);
  const imageSlots = useMemo(() => convertToImageSlots(node.subHeroImages), [node.subHeroImages]);

  return <HeroModule variant="homepageHeroWithForm" slots={slots} form={form} imageSlots={imageSlots} />;
}
