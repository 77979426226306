import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';

import convertToSlots from '../submodules/SubHeroContent';
import { convertToFormBlock } from '../submodules/SubHeroForm';
import convertToImageSlots from '../submodules/SubHeroImages';

interface Props {
  node: HeroContentWithFormFragment;
}

export default function HeroContentWithForm({ node }: Props) {
  const slots = useMemo(() => convertToSlots(node.subHeroContent), [node.subHeroContent]);
  const form = useMemo(() => convertToFormBlock(node.subHeroForm), [node.subHeroForm]);
  const imageSlots = useMemo(() => convertToImageSlots(node.subHeroImages), [node.subHeroImages]);

  if (!form) return <HeroModule variant="default" slots={slots} image={node.image?.[0]} imageSlots={imageSlots} />;
  return <HeroModule variant="withForm" slots={slots} form={form} image={node.image?.[0]} imageSlots={imageSlots} />;
}
