import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';
import slotDynamicButton, { slotDynamicButtonVariants } from '@/components/HeroModule/slots/DynamicButton';
import { extractItems } from '@/lib/GraphQLHelper';
import { SSRContext } from '@/typings/SSRContext';

import convertToSlots from '../submodules/SubHeroHomepageCTA';

interface Props {
  node: HeroHomepageWithImageFragment;
}

const HeroHomepageWithImage = ({ node }: Props) => {
  const headlines = node.heroHeadlines?.trim().split('\n') || [];
  const slots = useMemo(() => convertToSlots(node.subHeroHomepageCta), [node.subHeroHomepageCta]);
  const pills = useMemo(
    () => extractItems<HomepageHeroCtaPillsFragment>(node.homepageHeroCtaPills),
    [node.homepageHeroCtaPills],
  );
  const image = node.image?.[0];

  if (!image) return null;
  return (
    <HeroModule
      variant="homepageHeroWithImage"
      slots={slots}
      imageSlots={[]}
      headlines={headlines}
      image={image}
      pills={pills}
      overrideBackground={node.backgroundColour}
    />
  );
};

HeroHomepageWithImage.prefetch = async (context: SSRContext, node: HeroHomepageWithImageFragment) => {
  // prefetch dynamicButton
  const nodes = node.subHeroHomepageCta?.nodes || [];
  for (const node of nodes) {
    if (
      node &&
      '__typename' in node &&
      node?.__typename === 'subHeroHomepageCta_dynamicButton_BlockType' &&
      node.heroModuleDynamicButtonVariants
    ) {
      await slotDynamicButton.prefetch(context, node.heroModuleDynamicButtonVariants as slotDynamicButtonVariants);
    }
  }

  return;
};

export default HeroHomepageWithImage;
