import { HeroFormBlockProps } from '@/components/HeroModule/common/HeroFormBlock';
import { extractItems } from '@/lib/GraphQLHelper';

export type ChildNode =
  | VizyHeadingFragment
  | VizyParagraphFragment
  | SubHeroFormButtonFragment
  | SubHeroFormRegoLookupFragment;

export function convertToFormBlock(node?: Nullable<SubHeroFormFragment>): Nullable<HeroFormBlockProps> {
  const children = extractItems<ChildNode>(node?.nodes);
  const heading = children?.find((node): node is VizyHeadingFragment => node.__typename === 'VizyNode_Heading')?.html;
  const paragraph = children?.find(
    (node): node is VizyParagraphFragment => node.__typename === 'VizyNode_Paragraph',
  )?.html;

  const buttonFragments =
    children?.filter((node): node is SubHeroFormButtonFragment => node.__typename === 'subHeroForm_button_BlockType') ||
    [];
  const buttons = buttonFragments
    .filter((item: SubHeroFormButtonFragment) => !!item.customLinkField)
    .map((item: SubHeroFormButtonFragment) => ({
      link: item.customLinkField as CustomLinkFieldFragment,
      icon: item.icon || null,
      buttonStyle: item.heroModuleDynamicButtonStyle || null,
    }));
  const regoLookupForm = children?.filter(
    (node): node is SubHeroFormRegoLookupFragment => node.__typename === 'subHeroForm_regoLookup_BlockType',
  )?.[0];

  if (!heading || (!buttons.length && !regoLookupForm)) return null;

  return {
    heading: heading,
    paragraph: paragraph || undefined,
    buttons,
    regoLookupForm,
  };
}
