import React, { useMemo } from 'react';

import { extractItems } from '@/lib/GraphQLHelper';
import { SSRContext } from '@/typings/SSRContext';

import HeroModule, { HeroModuleNode, prefetchModule } from './modules';

import styles from './styles.module.scss';

interface Props {
  content: HeroBuilderFragment;
  children?: React.ReactNode;
}

export default function HeroBuilder({ content }: Props) {
  const nodes = useMemo(() => extractItems<HeroModuleNode>(content.nodes), [content.nodes]);

  return (
    <div className={styles.heroBuilder}>
      {nodes?.map((node, i) => (
        <HeroModule node={node} key={`${node.__typename}-${i}`} />
      ))}
    </div>
  );
}

export async function prefetchHeroBuilder(context: SSRContext, content: HeroBuilderFragment) {
  const nodes = extractItems<HeroModuleNode>(content.nodes) || [];
  await Promise.all(nodes.map((node) => prefetchModule(context, node)));
}
