import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';
import slotDynamicButton, { slotDynamicButtonVariants } from '@/components/HeroModule/slots/DynamicButton';
import { extractItems } from '@/lib/GraphQLHelper';
import { SSRContext } from '@/typings/SSRContext';

import convertToSlots from '../submodules/SubHeroHomepageCTA';

interface Props {
  node: HeroHomepageWithVideoFragment;
}

const HeroHomepageWithVideo = ({ node }: Props) => {
  const headlines = node.heroHeadlines?.trim().split('\n') || [];
  const slots = useMemo(() => convertToSlots(node.subHeroHomepageCta), [node.subHeroHomepageCta]);
  const videos = useMemo(() => extractItems<VideoAssetFragment>(node.videoAsset), [node.videoAsset]);
  const pills = useMemo(
    () => extractItems<HomepageHeroCtaPillsFragment>(node.homepageHeroCtaPills),
    [node.homepageHeroCtaPills],
  );

  if (!videos) return null;
  return (
    <HeroModule
      variant="homepageHeroWithVideo"
      slots={slots}
      imageSlots={[]}
      headlines={headlines}
      videos={videos}
      image={node.image?.[0]}
      pills={pills}
    />
  );
};

HeroHomepageWithVideo.prefetch = async (context: SSRContext, node: HeroHomepageWithVideoFragment) => {
  // prefetch dynamicButton
  const nodes = node.subHeroHomepageCta?.nodes || [];
  for (const node of nodes) {
    if (
      node &&
      '__typename' in node &&
      node?.__typename === 'subHeroHomepageCta_dynamicButton_BlockType' &&
      node.heroModuleDynamicButtonVariants
    ) {
      await slotDynamicButton.prefetch(context, node.heroModuleDynamicButtonVariants as slotDynamicButtonVariants);
    }
  }

  return;
};

export default HeroHomepageWithVideo;
