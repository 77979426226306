import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';
import { extractItems } from '@/lib/GraphQLHelper';

import convertToSlots from '../submodules/SubHeroContent';

interface Props {
  node: HeroContentWithVideoFragment;
}

export default function HeroContentWithVideo({ node }: Props) {
  const slots = useMemo(() => convertToSlots(node.subHeroContent), [node.subHeroContent]);
  const videos = useMemo(() => extractItems<VideoAssetFragment>(node.videoAsset), [node.videoAsset]);

  if (!videos) return <HeroModule variant="default" slots={slots} imageSlots={[]} />;
  return <HeroModule variant="withVideo" slots={slots} videos={videos} image={node.image?.[0]} imageSlots={[]} />;
}
