import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';
import slotDynamicButton, { slotDynamicButtonVariants } from '@/components/HeroModule/slots/DynamicButton';
import { PillsLayoutType } from '@/components/HeroModule/variants/HomepageHero';
import { extractItems } from '@/lib/GraphQLHelper';
import { SSRContext } from '@/typings/SSRContext';

import convertToSlots from '../submodules/SubHeroHomepageCTA';

interface Props {
  node: HeroHomepageCtaFragment;
}
const HeroHomepageCTA = ({ node }: Props) => {
  const slots = useMemo(() => convertToSlots(node.subHeroHomepageCta), [node.subHeroHomepageCta]);
  const pills = useMemo(
    () => extractItems<HomepageHeroCtaPillsFragment>(node.homepageHeroCtaPills),
    [node.homepageHeroCtaPills],
  );
  const linkField = node.linkField?.[0];
  const pillsLayout = (node.pillsLayout || 'deafult') as PillsLayoutType;
  return (
    <HeroModule
      variant="homepageHero"
      slots={slots}
      imageSlots={[]}
      image={node.image?.[0]}
      pills={pills}
      linkField={linkField}
      pillsLayout={pillsLayout}
    />
  );
};

HeroHomepageCTA.prefetch = async (context: SSRContext, node: HeroHomepageCtaFragment) => {
  // prefetch dynamicButton
  const nodes = node.subHeroHomepageCta?.nodes || [];
  for (const node of nodes) {
    if (
      node &&
      '__typename' in node &&
      node?.__typename === 'subHeroHomepageCta_dynamicButton_BlockType' &&
      node.heroModuleDynamicButtonVariants
    ) {
      await slotDynamicButton.prefetch(context, node.heroModuleDynamicButtonVariants as slotDynamicButtonVariants);
    }
  }

  return;
};

export default HeroHomepageCTA;
