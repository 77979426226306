import { HeroSlots } from '@/components/HeroModule';
import { HeroSlotType } from '@/components/HeroModule/slots';
import { extractItems } from '@/lib/GraphQLHelper';

type ChildNode =
  | VizyParagraphFragment
  | VizyBulletListFragment
  | VizyOrderedListFragment
  | SubHeroLandingHeadlineFragment
  | SubHeroLandingContentStaticButtonFragment;

export function convertToSlot(node: ChildNode): Nullable<HeroSlotType> {
  switch (node.__typename) {
    case 'VizyNode_Paragraph':
      if (!node.html) return null;
      return HeroSlots.Paragraph(node.html);
    case 'VizyNode_BulletList':
    case 'VizyNode_OrderedList':
      if (!node.html) return null;
      return HeroSlots.List(node.html);
    case 'subHeroLandingContent_headline_BlockType':
      if (!node.plainText) return null;
      return HeroSlots.HeadingWithIcon(node.plainText, node.icon);
    case 'subHeroLandingContent_staticButton_BlockType':
      if (!node.customLinkField) return null;
      return HeroSlots.StaticButton(node);
    default:
      return null;
  }
}

export default function convertToSlots(node?: Nullable<SubHeroLandingContentFragment>) {
  const nodes =
    extractItems<ChildNode>(node?.nodes)
      ?.map(convertToSlot)
      .filter((s): s is HeroSlotType => !!s) || [];

  return nodes;
}
