import React from 'react';

import { SSRContext } from '@/typings/SSRContext';

import HeroContent from './HeroContent';
import HeroContentWithForm from './HeroContentWithForm';
import HeroContentWithSearch from './HeroContentWithSearch';
import HeroContentWithVideo from './HeroContentWithVideo';
import HeroHomepageCTA from './HeroHomepageCTA';
import HeroHomepageWithCarousel from './HeroHomepageWithCarousel';
import HeroHomepageWithForm from './HeroHomepageWithForm';
import HeroHomepageWithImage from './HeroHomepageWithImage';
import HeroHomepageWithVideo from './HeroHomepageWithVideo';
import HeroLanding from './HeroLanding';

type HeroModuleNode =
  | HeroContentFragment
  | HeroContentWithFormFragment
  | HeroContentWithVideoFragment
  | HeroHomepageCtaFragment
  | HeroHomepageWithCarouselFragment
  | HeroHomepageWithFormFragment
  | HeroHomepageWithImageFragment
  | HeroHomepageWithVideoFragment
  | HeroLandingFragment
  | HeroContentWithSearchFragment;

interface Props {
  node: HeroModuleNode;
}

export default function HeroModule({ node }: Props) {
  switch (node.__typename) {
    case 'heroBuilder_hero_BlockType':
      return <HeroContent node={node} />;
    case 'heroBuilder_heroWithForm_BlockType':
      return <HeroContentWithForm node={node} />;
    case 'heroBuilder_heroWithVideo_BlockType':
      return <HeroContentWithVideo node={node} />;
    case 'heroBuilder_homepageHero_BlockType':
      return <HeroHomepageCTA node={node} />;
    case 'heroBuilder_homepageHeroWithForm_BlockType':
      return <HeroHomepageWithForm node={node} />;
    case 'heroBuilder_homepageHeroWithCarousel_BlockType':
      return <HeroHomepageWithCarousel node={node} />;
    case 'heroBuilder_homepageHeroWithImage_BlockType':
      return <HeroHomepageWithImage node={node} />;
    case 'heroBuilder_homepageHeroWithVideo_BlockType':
      return <HeroHomepageWithVideo node={node} />;
    case 'heroBuilder_heroLanding_BlockType':
      return <HeroLanding node={node} />;
    case 'heroBuilder_heroWithSearch_BlockType':
      return <HeroContentWithSearch node={node} />;
    /* istanbul ignore next */
    default:
      return null;
  }
}

export async function prefetchModule(context: SSRContext, node: HeroModuleNode) {
  switch (node.__typename) {
    case 'heroBuilder_homepageHero_BlockType':
      await HeroHomepageCTA.prefetch(context, node);
      break;
    case 'heroBuilder_homepageHeroWithCarousel_BlockType':
      await HeroHomepageWithCarousel.prefetch(context, node);
      break;
    case 'heroBuilder_homepageHeroWithImage_BlockType':
      await HeroHomepageWithImage.prefetch(context, node);
      break;
    case 'heroBuilder_homepageHeroWithVideo_BlockType':
      await HeroHomepageWithVideo.prefetch(context, node);
      break;
    case 'heroBuilder_hero_BlockType':
    case 'heroBuilder_heroWithForm_BlockType':
    case 'heroBuilder_heroWithVideo_BlockType':
    case 'heroBuilder_heroLanding_BlockType':
    case 'heroBuilder_heroWithSearch_BlockType':
    default:
      break;
  }
}

export type { HeroModuleNode };
