import React, { useMemo } from 'react';

import HeroModule from '@/components/HeroModule';

import convertToSlots from '../submodules/SubHeroContent';

interface Props {
  node: HeroContentWithSearchFragment;
  children?: React.ReactNode;
}

export default function HeroContentWithSearch({ node, children }: Props) {
  const slots = useMemo(() => convertToSlots(node.subHeroContent), [node.subHeroContent]);

  return (
    <HeroModule variant="withSearch" slots={slots} image={node.image?.[0]} imageSlots={[]}>
      {children}
    </HeroModule>
  );
}
