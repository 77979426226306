import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { GetServerSideProps } from 'next';
import Head from 'next/head';

import ContentBuilder from '@/components/ContentBuilder';
import HeroBuilder from '@/components/HeroBuilder';
import Footer from '@/components/layout/Footer';
import Header from '@/components/layout/Header';
import homePageQuery from '@/graphql/queries/homePageQuery.graphql';
import useSEO from '@/hooks/useSEO';
import useSiteQuery from '@/hooks/useSiteQuery';
import { getServerSidePropsWithGlobalData } from '@/lib/getServerSideProps';
import { hasHomepageHero, hasLandingHero } from '@/lib/HeroBuilderUtils';
import { loadPageData } from '@/lib/loadPageData';
import { withUrql } from '@/lib/urqlClient';
import { ErrorPage } from '@/pages/_error';
import styles from '@/pages/[...all]/index.module.scss';

function IndexPage() {
  const [seoHead, seoScripts] = useSEO();
  const [{ data, fetching, error }] = useSiteQuery<HomePageQueryVariables & { uri: string }, HomePageQuery>({
    query: homePageQuery,
    variables: { uri: '/' },
  });

  const isLandingPage = useMemo(() => {
    return data?.entry && '__typename' in data.entry && hasLandingHero(data.entry.heroBuilder!);
  }, [data]);

  if (fetching) return null;
  if (error) return <ErrorPage statusCode={500} err={error} />;

  if (!data?.entry || !('__typename' in data.entry)) {
    return <ErrorPage statusCode={404} />;
  }

  return (
    <>
      {seoScripts}
      <Head>{seoHead}</Head>
      {!isLandingPage && <Header homepageStyle={hasHomepageHero(data.entry.heroBuilder!)} />}
      <main data-testid="home">
        <div className={styles.page}>
          {data?.entry && 'heroBuilder' in data.entry && <HeroBuilder content={data.entry.heroBuilder!} />}
          {!isLandingPage && (
            <div className={styles.inner}>
              <ContentBuilder content={data.entry.contentBuilder!} />
            </div>
          )}
        </div>
      </main>
      {!isLandingPage && <Footer />}
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (ctx) =>
  await getServerSidePropsWithGlobalData(
    ctx,
    async (context) => {
      return await loadPageData(context, homePageQuery, { uri: '/' });
    },
    { cache: true },
  );

export default withUrql(observer(IndexPage));
